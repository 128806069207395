* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --background: #ff0;
  --text-color: black;
  --alt-color: white;
  --action-color: blue;
  --action-text: white;
  --danger-color: red;
  --danger-text: white;
  --disabled-color: lightgray;
  --disabled-text: black;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

body {
  min-height: 100vh;
  height: 100%;
  background-color: #ff0;
  background-color: var(--background, yellow);
  color: #000;
  color: var(--text-color, black);
  overflow: hidden;
}

h1 {
  font-size: 3rem;
}

#app {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
}

.danger {
  background: red;
  background: var(--danger-color, red);
  color: #fff;
  color: var(--danger-text, white);
}

.cancel {
  color: #000;
  background-color: #d3d3d3;
}

button {
  background: #00f;
  background: var(--action-color, blue);
  color: #fff;
  color: var(--action-text, white);
  border: none;
  padding: 1rem;
  font-size: 18px;
}

button:disabled {
  cursor: not-allowed;
  background: #8181ff;
}

nav {
  border-bottom: 3px solid var(--text-color);
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
  padding-top: 1rem;
  display: flex;
}

footer {
  background: #000;
  background: var(--text-color, black);
  color: #fff;
  color: var(--alt-color, white);
  padding: .5rem;
  font-size: 16px;
  position: sticky;
  top: 100%;
  bottom: 0;
}

.timer-form {
  background: #000;
  background: var(--text-color, black);
  color: #fff;
  color: var(--alt-color, white);
  max-width: 600px;
  box-shadow: 5px 5px 0px var(--text-color);
  border: 1px solid #ff0;
  margin: 0 auto;
  padding: 1.5rem 1rem;
}

.inputs {
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem auto;
  display: flex;
}

.inputs input {
  width: 100%;
  padding: 1rem;
  font-size: 20px;
}

.timer-form button {
  margin: 0 auto;
  display: block;
}

.sound-indicator:hover {
  cursor: pointer;
}

.sound-indicator img {
  height: 25px;
  width: 25px;
}

.quick-timers {
  grid-gap: 10px;
  max-width: 600px;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 1rem auto;
  display: flex;
}

.quick-timers button {
  width: 100%;
  text-align: center;
  margin: .5rem 0;
  padding: 1rem;
}

.end-time {
  text-align: center;
  padding: .5rem;
  font-size: 15px;
}

.timer {
  text-align: center;
}

.timer .time {
  font-family: monospace;
  font-size: 5rem;
  font-weight: bold;
}

.timer .actions {
  flex-direction: column;
  display: flex;
}

.actions > button {
  margin: .25rem;
}

@media screen and (width >= 500px) {
  .quick-timers button {
    width: 150px;
  }

  .actions {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }

  .actions > button {
    width: 200px;
  }
}

/*# sourceMappingURL=index.66839487.css.map */
