
* { box-sizing: border-box; margin: 0; padding: 0; }

:root {
    --background: rgb(255, 255, 0);
    --text-color: black;
    --alt-color: white;
    --action-color: blue;
    --action-text: white;
    --danger-color: red;
    --danger-text: white;
    --disabled-color: lightgray;
    --disabled-text: black;
}

html, body { margin: 0; padding: 0; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; }

body {
    min-height: 100vh;
    height: 100%;
    background-color: yellow;
    background-color: var(--background, yellow);
    color: black;
    color: var(--text-color, black);
    overflow: hidden;
}

h1 {
    font-size: 3rem;
}

#app {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
}

.danger {
    background: red;
    background: var(--danger-color, red);
    color: white;
    color: var(--danger-text, white);
}

.cancel {
    background-color: lightgray;
    color: black;
}

button {
    padding: 1rem;
    font-size: 18px;
    border: none;
    background: blue;
    background: var(--action-color, blue);
    color: white;
    color: var(--action-text, white);
}

button:disabled {
    cursor: not-allowed;
    background: rgb(129, 129, 255);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 1rem;
    border-bottom: 3px solid var(--text-color);
    margin-bottom: 2rem;
}

footer {
    position: sticky;
    bottom: 0;
    top: 100%;
    background: black;
    background: var(--text-color, black);
    color: white;
    color: var(--alt-color, white);
    padding: 0.5rem;
    font-size: 16px;
}

.timer-form {
    margin: 0 auto;
    background: black;
    background: var(--text-color, black);
    color: white;
    color: var(--alt-color, white);
    padding: 1.5rem 1rem;
    max-width: 600px;
    box-shadow: 5px 5px 0px var(--text-color);
    border: 1px solid yellow;
}

.inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem auto;
}

.inputs input {
    width: 100%;
    padding: 1rem;
    font-size: 20px;
}

.timer-form button {
    display: block;
    margin: 0 auto;
}

.sound-indicator:hover {
    cursor: pointer;
}

.sound-indicator img {
    height: 25px;
    width: 25px;
}

.quick-timers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    max-width: 600px;
    margin: 1rem auto;
}

.quick-timers button {
    width: 100%;
    margin: 0.5rem 0;
    padding: 1rem;
    text-align: center;
}

.end-time {
    text-align: center;
    font-size: 15px;
    padding: 0.5rem;
}

/* -------- timer styles ---------- */

.timer {
    text-align: center;
}

.timer .time {
    font-size: 5rem;
    font-weight: bold;
    font-family: monospace;
}

.timer .actions {
    display: flex;
    flex-direction: column;
}

.actions > button {
    margin: 0.25rem;
}

@media screen and (min-width: 500px) {
    .quick-timers button {
        width: 150px;
    }

    .actions {
        margin: 0 auto;
        width: fit-content;
    }

    .actions > button {
        width: 200px;
    }
}